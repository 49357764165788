<template>
    <validation-observer v-slot="{invalid}" ref="formValidator">
        <b-form @submit.prevent="onSubmit" autocomplete="off">
            <h6 class="heading-small text-muted mb-4 admin">{{$t('profile.user_information')}}</h6>
            <div class="pl-lg-4">
                <b-row>
                    <b-col lg="6">
                        <base-input type="text" name="name" :nameAs="$t('profile.name_lastname')" :label="$t('profile.name_lastname')" :placeholder="$t('profile.name_lastname')" v-model="user.name" required>
                        </base-input>
                    </b-col>
                    <b-col lg="6">
                        <base-input type="text" name="phone" :nameAs="$t('profile.phone')" :label="$t('profile.phone')" :placeholder="$t('profile.phone')" v-model="user.phone" required>
                        </base-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="6">
                        <base-input type="email" name="email" :nameAs="$t('profile.email')" :label="$t('profile.email')" :placeholder="$t('profile.email')" v-model="user.email" required>
                        </base-input>
                    </b-col>
                    <b-col lg="6">
                        <label class="form-control-label">{{$t('profile.password')}}</label>
                        <div class="password-div">
                            <base-input :type="showPassword?'text':'password'" name="password" :placeholder="$t('profile.password')" v-model="user.password" :rules="'min:6'">
                            </base-input>
                            <div class="showPassword" @click="showPassword = showPassword !== true">
                                <span v-if="!showPassword">
                                    <i class="fas fa-eye"></i>
                                </span>
                                <span v-else>
                                    <i class="fas fa-eye-slash"></i>
                                </span>
                            </div>
                        </div>

                        <small>{{$t('profile.pass_desc')}}</small>
                    </b-col>
                </b-row>
            </div>
            <hr class="my-4">
            <div class="text-right">
                <base-button type="primary" native-type="submit" :disabled="invalid">{{$t('save')}}</base-button>
            </div>
        </b-form>
    </validation-observer>
</template>

<script>
import { USER_UPDATE, USER_REQUEST } from '@/store/modules/define';
import { handleError } from '@/utils/func';

let self;
export default {
    data() {
        return {
            loading: false,
            user: {
                uuid: '',
                name: '',
                phone: '',
                email: '',
                password: ''
            },
            showPassword:false
        };
    },
    props: {
        profile: null
    },
    methods: {
        onSubmit() {
            let uuid = self.user.uuid;
            let user = _.pickBy(self.user, (item, key) => {
                if (key == 'uuid') {
                    return false;
                }

                switch (typeof item) {
                    case 'boolean':
                        return true;
                        break;
                    case 'string':
                        return item.trim() != '';
                        break;
                    default:
                        return true;
                        break;
                }
            });

            if (user.password && user.password.length < 6) {
                self.$notify({ type: 'warning', message: self.$i18n.t('error.password_length') });
                return false;
            }

            self.loading = true;
            self.$store
                .dispatch(USER_UPDATE, { uuid, user })
                .then(resp => {
                    self.loading = false;
                    self.user.password = '';
                    self.$store.dispatch(USER_REQUEST);
                    self.$notify({ type: 'success', message: self.$i18n.t('success.profile_update') });
                })
                .catch(err => {
                    self.loading = false;
                    handleError(self, err);
                });
        }
    },
    watch: {
        profile: val => {
            self.user = _.merge(self.user, val);
        }
    },
    created() {
        self = this;
        if (self.profile) {
            self.user = _.merge(self.user, self.profile);
        }
    }
};
</script>

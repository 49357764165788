<template>
	<validation-observer v-slot="{handleSubmit, invalid}" ref="formValidator">
		<div class="profile-header">
			<b-avatar variant="primary" v-if="!user.photo"></b-avatar>
			<b-avatar v-else :src="imgUrl + user.photo"></b-avatar>
			<h4 class="text-center">{{ user.title }} {{ user.name }}</h4>
		</div>
		<b-form role="form" @submit.prevent="handleSubmit(onSubmit)" v-loading="loading">
			<div class="accordion" role="tablist">
				<b-card no-body class="mb-1 ovisible">
					<b-card-header header-tag="header" class="p-1" role="tab">
						<b-button block v-b-toggle.pinfo variant="default">{{ $t('general.personal_info') }}</b-button>
					</b-card-header>
					<b-collapse id="pinfo" visible accordion="my-accordion" role="tabpanel">
						<b-card-body>
							<b-row>
								<b-col md="3">
									<base-input
										type="email"
										name="email"
										:nameAs="$t('profile.email')"
										:label="$t('profile.email') + '*'"
										:placeholder="$t('profile.email')"
										v-model="user.email"
										required
									>
									</base-input>
								</b-col>
								<b-col md="5">
									<label class="form-control-label">{{ $t('profile.phone') }}*</label>
									<VuePhoneNumberInput
										id="phoneNumber"
										v-model="user.maskPhone"
										required
										class="mb-2"
										:translations="{
											example: $t('general.example'),
											countrySelectorError: $t('error.select_country'),
											countrySelectorLabel: $t('general.country_code'),
											phoneNumberLabel: $t('general.phone_number')
										}"
										:defaultCountryCode="user.defaultCountryCode"
										show-code-on-list
										@update="onPhone"
										no-flags
									/>
								</b-col>
								<b-col md="4">
									<label class="form-control-label">{{ $t('profile.password') }}</label>
									<div class="password-div">
										<base-input
											:type="showPassword ? 'text' : 'password'"
											name="password"
											:placeholder="$t('profile.password')"
											v-model="user.password"
											:rules="'min:6'"
										>
										</base-input>
										<div class="showPassword" @click="showPassword = showPassword !== true">
											<span v-if="!showPassword">
												<i class="fas fa-eye"></i>
											</span>
											<span v-else>
												<i class="fas fa-eye-slash"></i>
											</span>
										</div>
									</div>
								</b-col>
							</b-row>
							<b-row>
								<b-col md="12">
									<b-form-group>
										<b-tabs content-class="mt-3">
											<b-tab :active="key == 0" :title="lang.toUpperCase()" v-for="(lang, key) in setting.lang" :key="key">
												<quill-editor v-model="user.desc[lang]" :options="editorOption" />
											</b-tab>
										</b-tabs>
									</b-form-group>
								</b-col>
							</b-row>
						</b-card-body>
					</b-collapse>
				</b-card>
				<b-card no-body class="mb-1">
					<b-card-header header-tag="header" class="p-1" role="tab">
						<b-button block v-b-toggle.address variant="default" @click="setCenter">{{ $t('general.address_info') }}</b-button>
					</b-card-header>
					<b-collapse id="address" accordion="my-accordion" role="tabpanel">
						<b-card-body>
							<b-row>
								<b-col md="6">
									<base-input
										type="text"
										name="address"
										:nameAs="$t('general.address')"
										:label="$t('general.address')"
										:placeholder="$t('general.address')"
										v-model="user.address"
									>
									</base-input>
								</b-col>
								<b-col md="3">
									<base-input :label="$t('general.country') + '*'" name="country" :nameAs="$t('general.country')" :rules="'required'">
										<el-select
											v-model="user.country"
											:placeholder="$t('general.select')"
											:no-match-text="$t('warning.no_match')"
											:no-data-text="$t('warning.no_data')"
											required
										>
											<el-option v-for="(item, key) in countries" :key="key" :label="item.name" :value="item._id"></el-option>
										</el-select>
									</base-input>
								</b-col>
								<b-col md="3">
									<base-input :label="$t('general.city') + '*'" name="city" :nameAs="$t('general.city')" :rules="'required'">
										<el-select
											v-model="user.city"
											filterable
											:no-match-text="$t('warning.no_match')"
											:no-data-text="$t('warning.no_data')"
											:placeholder="$t('general.select')"
											@change="changeCity"
											required
										>
											<el-option v-for="(item, key) in cities" :key="key" :label="item.name" :value="item._id"></el-option>
										</el-select>
									</base-input>
								</b-col>
								<b-col>
									<div class="simple-map">
										<GmapMap :center.sync="center" :options="mapOptions" map-type-id="terrain" @click="markerDrag">
											<GmapMarker
												:position="user.location"
												:clickable="true"
												:draggable="true"
												@dragend="markerDrag"
												v-if="user.location.lat > 0"
											/>
										</GmapMap>
									</div>
								</b-col>
							</b-row>
						</b-card-body>
					</b-collapse>
				</b-card>

				<b-card no-body class="mb-1">
					<b-card-header header-tag="header" class="p-1" role="tab">
						<b-button block v-b-toggle.photos variant="default">{{ $t('general.photos') }}</b-button>
					</b-card-header>
					<b-collapse id="photos" accordion="my-accordion" role="tabpanel">
						<b-card-body>
							<b-form-file
								multiple
								accept="image/jpeg, image/png"
								size="md"
								v-model="photos.list"
								:placeholder="$t('general.select_img')"
								:drop-placeholder="$t('general.drop_file')"
								:browse-text="$t('general.choose')"
								ref="photos"
							>
								<template slot="file-name" slot-scope="{names}">
									<b-badge variant="dark">{{ names[0] }}</b-badge>
									<b-badge v-if="names.length > 1" variant="dark" class="ml-1"> + {{ names.length - 1 }} More files </b-badge>
								</template>
							</b-form-file>
							<p>
								<br />
								<base-button type="primary" size="sm" @click="uploadPictures(user.uuid, 'photos')">{{
									$t('general.upload')
								}}</base-button>
							</p>

							<b-row>
								<b-col md="3" v-for="(picture, index) in user.photos" :key="index">
									<b-card no-body class="mt-2">
										<img :src="imgUrl + picture" alt="" />
										<template #footer>
											<base-button type="danger" block size="sm" @click="removePicture(user.uuid, picture, 'photos')">{{
												$t('general.delete')
											}}</base-button>
										</template>
									</b-card>
								</b-col>
							</b-row>
						</b-card-body>
					</b-collapse>
				</b-card>

				<b-card no-body class="mb-1">
					<b-card-header header-tag="header" class="p-1" role="tab">
						<b-button block v-b-toggle.diplomasAndCertificates variant="default">{{ $t('general.diplomasAndCertificates') }}</b-button>
					</b-card-header>
					<b-collapse id="diplomasAndCertificates" accordion="my-accordion" role="tabpanel">
						<b-card-body>
							<b-form-file
								multiple
								accept="image/jpeg, image/png"
								size="md"
								v-model="diplomasAndCertificates.list"
								:placeholder="$t('general.select_img')"
								:drop-placeholder="$t('general.drop_file')"
								:browse-text="$t('general.choose')"
								ref="diplomasAndCertificates"
							>
								<template slot="file-name" slot-scope="{names}">
									<b-badge variant="dark">{{ names[0] }}</b-badge>
									<b-badge v-if="names.length > 1" variant="dark" class="ml-1"> + {{ names.length - 1 }} More files </b-badge>
								</template>
							</b-form-file>
							<p>
								<br />
								<base-button type="primary" size="sm" @click="uploadPictures(user.uuid, 'diplomasAndCertificates')">{{
									$t('general.upload')
								}}</base-button>
							</p>

							<b-row>
								<b-col md="3" v-for="(picture, index) in user.diplomasAndCertificates" :key="index">
									<b-card no-body class="mt-2">
										<img :src="imgUrl + picture" alt="" />
										<template #footer>
											<base-button
												type="danger"
												block
												size="sm"
												@click="removePicture(user.uuid, picture, 'diplomasAndCertificates')"
												>{{ $t('general.delete') }}</base-button
											>
										</template>
									</b-card>
								</b-col>
							</b-row>
						</b-card-body>
					</b-collapse>
				</b-card>

				<b-card no-body class="mb-1">
					<b-card-header header-tag="header" class="p-1" role="tab">
						<b-button block v-b-toggle.social variant="default">{{ $t('general.social_info') }}</b-button>
					</b-card-header>
					<b-collapse id="social" accordion="my-accordion" role="tabpanel">
						<b-card-body>
							<b-row>
								<b-col md="6" class="text-capitalize">
									<base-input
										type="text"
										label="Facebook"
										:placeholder="$t('general.account_url')"
										v-model="user.social.facebook"
										:prependIcon="'fab fa-facebook'"
									>
									</base-input>
								</b-col>
								<b-col md="6" class="text-capitalize">
									<base-input
										type="text"
										label="Instagram"
										:placeholder="$t('general.account_url')"
										v-model="user.social.instagram"
										:prependIcon="'fab fa-instagram'"
									>
									</base-input>
								</b-col>
								<b-col md="6" class="text-capitalize">
									<base-input
										type="text"
										label="Twitter"
										:placeholder="$t('general.account_url')"
										v-model="user.social.twitter"
										:prependIcon="'fab fa-twitter'"
									>
									</base-input>
								</b-col>
								<b-col md="6" class="text-capitalize">
									<base-input
										type="text"
										label="Youtube"
										:placeholder="$t('general.account_url')"
										v-model="user.social.youtube"
										:prependIcon="'fab fa-youtube'"
									>
									</base-input>
								</b-col>
							</b-row>
						</b-card-body>
					</b-collapse>
				</b-card>
			</div>
			<hr class="my-4" />
			<b-alert show variant="warning" v-if="invalid || user.phone == ''">
				<strong>{{ $t('warning.fill_title') }} : </strong> {{ $t('warning.fill') }}
			</b-alert>
			<div class="text-right">
				<base-button type="primary" native-type="submit" :disabled="invalid || user.phone == ''">{{ $t('save') }}</base-button>
			</div>
		</b-form>
	</validation-observer>
</template>

<script>
import Vue from 'vue'
import {findPhoneNumbersInText} from 'libphonenumber-js'
import {DOCTOR_UPDATE, USER_REQUEST, DOCTOR_PICTUREUPLOAD, DOCTOR_PICTUREDELETE} from '@/store/modules/define'
import {handleError} from '@/utils/func'
import VuePhoneNumberInput from '@/components/PhoneInput'
import {mapGetters} from 'vuex'
import config from '@/config'
let self
export default {
	components: {VuePhoneNumberInput},
	computed: {
		...mapGetters({countries: 'getCountry', cities: 'getCity', setting: 'generalSetting'})
	},
	data() {
		const mapOptions = this.$root.defaultMapOptions
		return {
			editorOption: {
				modules: {
					clipboard: {
						matchVisual: true
					},
					toolbar: [
						['bold', 'italic', 'underline', 'strike'],
						['blockquote'],
						[{list: 'ordered'}, {list: 'bullet'}],
						[{indent: '-1'}, {indent: '+1'}],
						[{size: ['small', false, 'large', 'huge']}],
						[{header: [1, 2, 3, 4, 5, 6, false]}],
						[{color: []}, {background: []}],
						[{font: []}],
						[{align: []}],
						['clean'],
						['link', 'image']
					]
				},
				placeholder: ''
			},
			loading: false,
			center: mapOptions.center,
			mapOptions: mapOptions,
			imgUrl: config.VUE_APP_CDNURL + '/profile/',
			user: {
				uuid: '',
				name: '',
				maskPhone: '',
				defaultCountryCode: '',
				countryCode: '',
				phone: '',
				email: '',
				password: '',
				desc: '',
				address: '',
				country: '',
				city: '',
				location: {},
				social: {}
			},
			showPassword: false,
			photos: {
				loading: false,
				list: [],
				files: null
			},
			diplomasAndCertificates: {
				loading: false,
				list: [],
				files: null
			}

		}
	},
	props: {
		profile: null
	},
	methods: {
		setCenter() {
			if (self.user.location && self.user.location.lat) {
				_.set(self, 'center', {lat: 0, lng: 0})
				setTimeout(() => {
					self.center = _.cloneDeep(self.user.location)
				}, 500)
			}
		},
		changeCity(value) {
			let city = _.get(
				_.filter(self.cities, item => {
					return item._id == value
				}),
				0
			)

			let marker = {lat: parseFloat(city.latitude), lng: parseFloat(city.longitude)}
			_.set(self.user, 'location', marker)
			self.center = _.cloneDeep(marker)
		},
		markerDrag(location) {
			let marker = {lat: location.latLng.lat(), lng: location.latLng.lng()}
			_.set(self.user, 'location', marker)
			self.center = _.cloneDeep(marker)
		},
		nonTc() {
			this.$set(self.user, 'identity', self.user.nontc ? '11111111110' : '')
		},
		onPhone(payload) {
			if (payload.isValid) {
				this.$set(self.user, 'countryCode', payload.countryCallingCode)
				this.$set(self.user, 'phone', payload.nationalNumber)
			} else {
				this.$set(self.user, 'countryCode', '')
				this.$set(self.user, 'phone', '')
			}
		},
		onSubmit() {
			let uuid = self.user.uuid
			let user = _.pickBy(self.user, (item, key) => {
				if (key == 'uuid') {
					return false
				}

				switch (typeof item) {
					case 'boolean':
						return true
						break
					case 'string':
						return item.trim() != ''
						break
					default:
						return true
						break
				}
			})

			if (user.password && user.password.length < 6) {
				self.$notify({type: 'warning', message: self.$i18n.t('error.password_length')})
				return false
			}

			self.loading = true
			self.$store
				.dispatch(DOCTOR_UPDATE, {uuid, doctor: user})
				.then(resp => {
					self.loading = false
					self.user.password = ''
					self.$store.dispatch(USER_REQUEST)
					self.$notify({type: 'success', message: self.$i18n.t('success.profile_update')})
				})
				.catch(err => {
					self.loading = false
					handleError(self, err)
				})
		},


		async uploadPictures(uuid, type) {
			this.loading = true
			try {
				var response = await this.$store.dispatch(DOCTOR_PICTUREUPLOAD, {files: this[type].list, user: uuid, type})
				if (response.status) {
					var countPictures = this.user[type].length
					response.pictures.forEach((item, index) => {
						Vue.set(this.user[type], countPictures + index, item)
					})
				}
				this.$notify({type: 'success', message: this.$i18n.t('success.positive')})
				this.$refs[type].reset()
				this.loading = false
			} catch (err) {
				this.loading = false
				handleError(this, err)
			}
		},

		async removePicture(user, picture, type) {
			const result = await this.$swal({
				title: this.$i18n.t('quest.sure'),
				text: this.$i18n.t('quest.delete_info'),
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#f5365c',
				confirmButtonText: this.$i18n.t('quest.delete'),
				cancelButtonText: this.$i18n.t('quest.cancel')
			})
			if (result.value) {
				this.loading = true
				try {
					await this.$store.dispatch(DOCTOR_PICTUREDELETE, {user, picture, type})
					this.loading = false
					this.$delete(this.user[type], this.user[type].indexOf(picture))
					this.$notify({type: 'success', message: self.$i18n.t('success.positive')})
				} catch (err) {
					this.loading = false
					handleError(this, err)
				}
			}
		},

	},
	watch: {
		profile: val => {
			self.user = _.merge(self.user, val)
			_.forEach(self.user.desc, (v, i) => {
				self.user.desc[i] = v.replace(/[\\]/g, '')
			})

			if (val.phone) {
				let parsePhone = findPhoneNumbersInText('+' + self.user.phone.toString())
				if (parsePhone && parsePhone[0]) {
					let country = parsePhone[0].number.country
					let phone = parsePhone[0].number.nationalNumber
					let countryCode = parsePhone[0].number.countryCallingCode
					self.$set(self.user, 'defaultCountryCode', country)
					self.$set(self.user, 'countryCode', countryCode)
					self.$set(self.user, 'maskPhone', phone)
				}
			}
		}
	},
	created() {
		self = this
		if (self.profile) {
			self.user = _.merge(self.user, self.profile)
			_.forEach(self.user.desc, (v, i) => {
				self.user.desc[i] = v.replace(/[\\]/g, '')
			})

			if (self.profile.phone) {
				let parsePhone = findPhoneNumbersInText('+' + self.profile.phone.toString())
				if (parsePhone && parsePhone[0]) {
					let country = parsePhone[0].number.country
					let phone = parsePhone[0].number.nationalNumber
					let countryCode = parsePhone[0].number.countryCallingCode
					self.$set(self.user, 'defaultCountryCode', country)
					self.$set(self.user, 'countryCode', countryCode)
					self.$set(self.user, 'maskPhone', phone)
				}
			}
		}
	}
}
</script>
